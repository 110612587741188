import { ColorToken, Text } from '@mindfulchefuk/design-system'
import React, { FunctionComponent } from 'react'

const MainCopy: FunctionComponent<{ id?: string }> = ({
  children,
  id = null,
}) => (
  <Text
    as="h2"
    variant={{ base: 'body-sm', md: 'body-md' }}
    mx="auto"
    textAlign="center"
    color="pepper"
    id={id}
  >
    {children}
  </Text>
)

const CopyHighlight: FunctionComponent<{ color?: ColorToken; id?: string }> = ({
  children,
  color = 'pepper',
  id = null,
}) => (
  <Text
    color={color}
    as="span"
    variant={{ base: 'body-sm-500', md: 'body-md-500' }}
    id={id ? `${id}-highlight` : null}
  >
    {children}
  </Text>
)

export const partnerships = [
  {
    id: 'kings-award-for-enterprise',
    name: "King's Award for Enterprise",
    image: 'homepage/partners/kings-award-for-enterprise-2024.png',
    width: 56,
    copy: (
      <MainCopy id="homepage-partnership-kings-award">
        The King’s Awards for Enterprise 2024{' '}
        <CopyHighlight id="homepage-partnership-kings-award">
          Sustainable Development Winner
        </CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'b-corp',
    name: 'Certified B-Corp',
    image: 'homepage11-2022/partnerships/b-corp.png',
    width: 34,
    copy: (
      <MainCopy id="homepage-partnership-b-corp-copy">
        In the{' '}
        <CopyHighlight id="homepage-partnership-b-corp">
          top 3% of food B Corps{' '}
        </CopyHighlight>
        globally
      </MainCopy>
    ),
  },
  {
    id: 'one-feeds-two',
    name: 'One Feeds Two',
    image: 'homepage11-2022/partnerships/one-feeds-two.png',
    width: 107,
    copy: (
      <MainCopy id="homepage-partnership-one-feeds-two-copy">
        <CopyHighlight id="homepage-partnership-one-feeds-two">
          Buy a recipe box,{' '}
        </CopyHighlight>
        give a meal to children in need
      </MainCopy>
    ),
  },
  {
    id: 'vitality',
    name: 'Vitality',
    image: 'homepage11-2022/partnerships/vitality.png',
    width: 96,
    copy: (
      <MainCopy id="homepage-partnership-vitality-copy">
        Rewarding{' '}
        <CopyHighlight id="homepage-partnership-vitality-copy">
          healthy lifestyles
        </CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'regenerative-farming',
    name: 'Regenerative Farming',
    image: 'homepage11-2022/partnerships/regenerative-farming.png',
    width: 58,
    copy: (
      <MainCopy id="homepage-partnership-regenerative-farming-copy">
        Supporting British farmers on{' '}
        <CopyHighlight id="homepage-partnership-regenerative-farming">
          regenerative farming practices
        </CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'carbon-neutral',
    name: 'Carbon neutral',
    image: 'homepage11-2022/partnerships/carbon-neutral.png',
    width: 66,
    copy: (
      <MainCopy id="homepage-partnership-carbon-neutral-copy">
        Committed to{' '}
        <CopyHighlight id="homepage-partnership-carbon-neutral">
          Net Zero 2030
        </CopyHighlight>
      </MainCopy>
    ),
  },
]

export const bbcGoodFoodPartnerships = [
  {
    id: 'bbc-good-food',
    name: 'BBC Good Food',
    image: 'homepage11-2022/partnerships/partnership-good-food.png',
    width: 180,
    copy: (
      <MainCopy>
        <CopyHighlight color="bbc-good-food-pink">Mindful Chef</CopyHighlight>{' '}
        partnered with{' '}
        <CopyHighlight color="bbc-good-food-pink">Good Food</CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'b-corp',
    name: 'Certified B-Corp',
    image: 'homepage11-2022/partnerships/b-corp.png',
    width: 34,
    copy: (
      <MainCopy>
        In the <CopyHighlight>top 3% of food B Corps </CopyHighlight>
        globally
      </MainCopy>
    ),
  },
  {
    id: 'one-feeds-two',
    name: 'One Feeds Two',
    image: 'homepage11-2022/partnerships/one-feeds-two.png',
    width: 107,
    copy: (
      <MainCopy>
        <CopyHighlight color="bbc-good-food-pink">
          Buy a recipe box, give a meal{' '}
        </CopyHighlight>
        to children in need
      </MainCopy>
    ),
  },
  {
    id: 'vitality',
    name: 'Vitality',
    image: 'homepage11-2022/partnerships/vitality.png',
    width: 96,
    copy: (
      <MainCopy>
        Rewarding{' '}
        <CopyHighlight color="bbc-good-food-pink">
          healthy lifestyles
        </CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'regenerative-farming',
    name: 'Regenerative Farming',
    image: 'homepage11-2022/partnerships/regenerative-farming.png',
    width: 58,
    copy: (
      <MainCopy>
        Supporting British farmers on{' '}
        <CopyHighlight color="bbc-good-food-pink">
          regenerative farming practices
        </CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'carbon-neutral',
    name: 'Carbon neutral',
    image: 'homepage11-2022/partnerships/carbon-neutral.png',
    width: 66,
    copy: (
      <MainCopy>
        Committed to <CopyHighlight>Net Zero 2030</CopyHighlight>
      </MainCopy>
    ),
  },
]

export const newbiePartnerships = [
  {
    id: 'bbc-good-food',
    name: 'BBC Good Food',
    image: 'homepage11-2022/partnerships/bbc-good-food.png',
    width: 180,
    copy: (
      <MainCopy>
        Partner with <CopyHighlight color="veg2">BBC Good Food</CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'b-corp',
    name: 'Certified B-Corp',
    image: 'homepage11-2022/partnerships/b-corp.png',
    width: 34,
    copy: (
      <MainCopy>
        In the <CopyHighlight>top 3% of food B Corps </CopyHighlight>
        globally
      </MainCopy>
    ),
  },
  {
    id: 'one-feeds-two',
    name: 'One Feeds Two',
    image: 'homepage11-2022/partnerships/one-feeds-two.png',
    width: 107,
    copy: (
      <MainCopy>
        <CopyHighlight color="veg2">
          Buy a recipe box, give a meal
        </CopyHighlight>
        to children in need
      </MainCopy>
    ),
  },
  {
    id: 'vitality',
    name: 'Vitality',
    image: 'homepage11-2022/partnerships/vitality.png',
    width: 96,
    copy: (
      <MainCopy>
        Rewarding <CopyHighlight color="veg2">healthy lifestyles</CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'regenerative-farming',
    name: 'Regenerative Farming',
    image: 'homepage11-2022/partnerships/regenerative-farming.png',
    width: 58,
    copy: (
      <MainCopy>
        Supporting British farmers on{' '}
        <CopyHighlight color="veg2">
          regenerative farming practices
        </CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'carbon-neutral',
    name: 'Carbon neutral',
    image: 'homepage11-2022/partnerships/carbon-neutral.png',
    width: 66,
    copy: (
      <MainCopy>
        Committed to <CopyHighlight>Net Zero 2030</CopyHighlight>
      </MainCopy>
    ),
  },
]
